export const copyToClipboardMixin = {
    methods: {
      copyToClipboard(textToCopy) {
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            console.log('Text copied to clipboard:', textToCopy);
            this.$message({type:"success",message:"复制成功"})
            // 在这里你可以添加一些提示或处理成功的逻辑
          })
          .catch((error) => {
            console.error('Copy to clipboard failed:', error);
            this.$message({type:"error",message:"复制失败"+error})
            // 在这里你可以添加一些提示或处理失败的逻辑
          });
      },
    },
};